const environment = process.env.GATSBY_ENV;
const websiteUrl = {
  development: 'http://localhost',
  staging: 'https://bam-blog-staging.netlify.app',
  uat: 'https://bam-blog-uat.netlify.app',
};

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Be Amazed', // Navigation and Site Title
  titleAlt: 'Be Amazed', // Title for JSONLD
  description: 'Amazingly interesting facts you never knew you wanted to know.',
  headline: 'Amazingly interesting facts you never knew you wanted to know.', // Headline for schema.org JSONLD
  url: websiteUrl[environment] || 'https://beamazed.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/images/logo.png', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.jpeg', // Used for manifest favicon generation
  shortName: 'Be Amazed', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Be Amazed Media', // Author for schemaORGJSONLD
  themeColor: '#154fdd',
  backgroundColor: '#fff',

  twitter: '@beamazedvideos', // Twitter Username
  facebook: 'BeAmazedMedia', // Facebook Site Name
};
