import { graphql, useStaticQuery } from 'gatsby';

export const useContentfulContactUs = () => {
  const { contentfulContactUs } = useStaticQuery(
    graphql`
      query {
        contentfulContactUs {
          id
          formTitle
          pageTitle
          pageSummary {
            internal {
              content
            }
          }
          formBody {
            internal {
              content
            }
          }
          metaDescription
        }
      }
    `
  );
  return contentfulContactUs;
};
