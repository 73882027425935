import React, { FunctionComponent, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Hidden,
  Visible,
  setConfiguration,
} from 'react-grid-system';
import { useLocation } from '@reach/router';

import './contact-us.scss';

import website from 'config/website';

import { SEO } from 'components/SEO';
import { Snackbar } from 'components/Snackbar';
import { PrimaryLayout } from 'components/PrimaryLayout';
import { InputField } from 'components/InputField';
import { Button } from 'components/Button';
import { CallToAction } from 'components/CallToAction';
import { useContentfulContactUs } from '../lib/queries/contact.queries';
import axios, { AxiosRequestConfig } from 'axios';
import { BACKEND_BASE_URL } from '../constants';

export type ContactFormProps = {
  formFields: FormProps;
  handleFormFieldChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | React.ChangeEventHandler<HTMLTextAreaElement>;
  isSnackbarActive: boolean;
  setIsSnackbarActive: React.Dispatch<React.SetStateAction<boolean>>;
  handleFormSubmit;
  errors;
  snackbarText: string;
};

export type FormProps = {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  organization: string;
  message: string;
};

setConfiguration({ breakpoints: [576, 782, 992, 1200, 1600] });
const noErrorState = {
  name: false,
  emailAddress: false,
  phoneNumber: false,
  organization: false,
  message: false,
  snackbar: false,
};

const SnackbarTexts = {
  success: 'Thank you! Your form has been submitted.',
  error: 'An error occurred, please try again.',
};
export type ContactUsPagePageProps = Record<string, unknown>;

const ContactUsPage: FunctionComponent<ContactUsPagePageProps> = () => {
  const [formFields, setFormFields] = useState<FormProps>({
    name: '',
    emailAddress: '',
    phoneNumber: '',
    organization: '',
    message: '',
  });
  const [errors, setErrors] = useState(noErrorState);
  const [isSnackbarActive, setIsSnackbarActive] = useState(false);

  const [snackbarText, setSnackbarText] = useState(SnackbarTexts.success);

  const [subscriptionSnackbar, setSubscriptionSnackbar] = useState({
    text: SubscriptionSnackbarTexts.success,
    active: false,
    error: false,
  });
  const contentfulContactUs = useContentfulContactUs();
  const handleFormFieldChange = (e) => {
    setFormFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleFormSubmit = () => {
    if (
      !formFields.name ||
      !formFields.phoneNumber ||
      !formFields.message ||
      !isEmailValid(formFields.emailAddress)
    ) {
      setErrors((pS) => ({
        ...pS,
        name: !formFields.name,
        phoneNumber: !formFields.phoneNumber,
        message: !formFields.message,
        emailAddress: !isEmailValid(formFields.emailAddress),
      }));
      return;
    }
    setErrors(noErrorState);

    const formData = JSON.stringify(formFields);
    const config = {
      method: 'POST',
      url: `${BACKEND_BASE_URL}/sendEmail`,
      headers: {
        'content-type': 'application/json',
      },
      data: formData,
    } as AxiosRequestConfig;

    axios(config)
      .then((response) => {
        if (response.status !== 200) {
          setSnackbarText(SnackbarTexts.error);
          setErrors((pS) => ({ ...pS, snackbar: true }));
        } else {
          setSnackbarText(SnackbarTexts.success);
          setErrors(noErrorState);
        }
        setIsSnackbarActive(true);
      })
      .catch((error) => {
        setSnackbarText(SnackbarTexts.error);
        setErrors((pS) => ({ ...pS, snackbar: true }));
      });
  };
  const contactFormProps = {
    errors,
    formFields,
    snackbarText,
    handleFormFieldChange,
    isSnackbarActive,
    setIsSnackbarActive,
    handleFormSubmit,
  };
  const handleSubscription = (email) => {
    if (!isEmailValid(email)) {
      setCtaError(true);
      return;
    }
    setCtaError(false);

    const formData = JSON.stringify({ email });
    const config = {
      // method: 'POST',
      // url: `${constants.BACKEND_BASE_URL}/sendEmail`,
      method: 'GET',
      url: 'https://jsonplaceholder.typicode.com/todos',
      headers: {
        'content-type': 'application/json',
      },
      data: formData,
    } as AxiosRequestConfig<any>;

    axios(config)
      .then((response) => {
        if (response.status !== 200) {
          setSubscriptionSnackbar({
            active: true,
            error: true,
            text: SubscriptionSnackbarTexts.failure,
          });
        } else {
          setSubscriptionSnackbar({
            error: false,
            active: true,
            text: SubscriptionSnackbarTexts.success,
          });
        }
      })
      .catch((error) => {
        setSubscriptionSnackbar({
          active: true,
          error: true,
          text: SubscriptionSnackbarTexts.failure,
        });
      });
  };
  const [ctaError, setCtaError] = useState(false);
  const location = useLocation();

  return (
    <PrimaryLayout>
      <SEO
        title={`${contentfulContactUs.pageTitle} | ${website.titleAlt}`}
        desc={contentfulContactUs.metaDescription}
        pathname={location.pathname}
      />
      <Container fluid style={{ padding: 0 }} className="contact-us-container">
        <Row
          className="outer-header-container"
          justify="center"
          style={{ margin: 0, marginTop: 30 }}
        >
          <Row className="header-container">
            <Col md={8} sm={12} style={{ padding: 0 }}>
              <h1 className="page-title">{contentfulContactUs.pageTitle}</h1>
              <p className="page-description">
                {contentfulContactUs.pageSummary.internal.content}
              </p>
            </Col>
          </Row>
        </Row>
        <Row
          justify="center"
          align="center"
          className="outer-contact-us-form-container"
          style={{ margin: 0 }}
        >
          <Row
            justify="start"
            align="center"
            style={{ margin: 0 }}
            className="middle-contact-us-form-container"
          >
            <Row
              justify="between"
              className="contact-us-form-container"
              style={{ margin: 0 }}
            >
              <Visible xs sm md>
                <Col
                  style={{
                    margin: 'auto',
                    padding: '30px 0 20px 0',
                  }}
                  className="mobile-contact-form"
                  xs={12}
                >
                  <Col
                    style={{
                      margin: 'auto',
                      marginBottom: 20,
                      maxWidth: 370,
                      padding: 35,
                    }}
                    xs={12}
                    className="form-container"
                  >
                    <ContactForm
                      {...contactFormProps}
                      padding={{ padding: '0 20px' }}
                    />
                  </Col>
                </Col>

                <Col
                  style={{
                    margin: 'auto',
                    padding: '30px 40px',
                  }}
                  xs={12}
                >
                  <ContactDescription
                    isMobile
                    contentfulContactUs={contentfulContactUs}
                  />
                </Col>
              </Visible>

              <Hidden sm xs md>
                <Col style={{ padding: 0 }} sm={6}>
                  <ContactDescription
                    contentfulContactUs={contentfulContactUs}
                  />
                </Col>
                <Col
                  sm={5}
                  style={{ padding: 0, maxWidth: 370 }}
                  className="form-container"
                >
                  <ContactForm {...contactFormProps} />
                </Col>
              </Hidden>
            </Row>
          </Row>
        </Row>
        <Col
          className="cta-container"
          style={{
            maxWidth: 1256,
            margin: 'auto',
            padding: '40px 10px',
          }}
          sm={12}
          xs={12}
        >
          <CallToAction
            header="Receive the latest articles "
            body="You’ll Be Amazed by our daily dose of amazing content."
            inputPlaceholder="Enter email here..."
            ctaText="Subscribe"
            isError={ctaError}
            snackbarError={subscriptionSnackbar.error}
            setIsSnackbarActive={(active: boolean) =>
              setSubscriptionSnackbar((pS) => ({ ...pS, active }))
            }
            isSnackbarActive={subscriptionSnackbar.active}
            errorText="Please enter your email address in the format yourname@example.com"
            snackbarText={subscriptionSnackbar.text}
            onAction={handleSubscription}
          />
        </Col>
      </Container>
    </PrimaryLayout>
  );
};

const ContactForm: FunctionComponent<ContactFormProps> = ({
  errors,
  formFields,
  handleFormFieldChange,
  isSnackbarActive,
  setIsSnackbarActive,
  snackbarText,
  handleFormSubmit,
}) => {
  const [isError, setIsError] = useState(false);
  return (
    <>
      {ContactFormShortFields.map((field) => (
        <Col
          className="form-field"
          style={{ padding: 0 }}
          sm={12}
          xs={12}
          key={field.placeholder}
        >
          <InputField
            className="contact-field"
            placeholder={field.placeholder}
            name={field.name}
            errorText={field?.errorText}
            isError={errors[field.name]}
            value={formFields[field.name]}
            onChange={handleFormFieldChange}
            extended={field.extended}
          />
        </Col>
      ))}
      <Col style={{ padding: 0 }} className="form-field" sm={12} xs={12}>
        <Button
          className="form-button"
          handleClick={() => {
            setIsError(!isError);
            handleFormSubmit();
          }}
          type="form"
        >
          Send
        </Button>

        <Snackbar
          isError={errors.snackbar}
          isActive={isSnackbarActive}
          setIsActive={setIsSnackbarActive}
          text={snackbarText}
        />
      </Col>
    </>
  );
};

const ContactDescription: FunctionComponent<{
  contentfulContactUs;
  isMobile?: boolean;
}> = ({ contentfulContactUs, isMobile = false }) => (
  <Col
    style={{
      marginTop: isMobile ? 0 : 20,
      padding: 0,
    }}
    md={12}
  >
    <p className="form-description">{contentfulContactUs.formTitle}</p>
    <div style={{ maxWidth: '80%' }}>
      <p className="form-description-body">
        {contentfulContactUs.formBody.internal.content}
      </p>
    </div>
  </Col>
);
const SubscriptionSnackbarTexts = {
  success: 'Thank you for signing up!',
  failure: 'An error occurred, please try again.',
};
const ContactFormShortFields = [
  {
    placeholder: 'Your name',
    name: 'name',
    errorText: 'Please enter your name',
  },
  {
    placeholder: 'Your email address',
    name: 'emailAddress',
    errorText: 'Please enter your email address in format youname@example.com',
  },
  {
    placeholder: 'Your phone number',
    name: 'phoneNumber',
    errorText: 'Please enter your phone number including area code',
  },
  {
    placeholder: 'Your organization',
    name: 'organization',
  },
  {
    extended: true,
    name: 'message',
    errorText: 'Please enter your message',
  },
];

const isEmailValid = (email) => {
  const emailCheckRegex = /\S+@\S+\.\S+/;
  return emailCheckRegex.test(email);
};

export default ContactUsPage;
