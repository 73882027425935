import React, { FunctionComponent, useState, useEffect } from 'react';

import { Button } from '../Button';
import { InputField } from '../InputField';
import { Snackbar } from '../Snackbar';
import './CallToAction.scss';

export type CallToActionProps = {
  variant?: 'full-screen' | 'sidebar';
  header: string;
  body: string;
  inputPlaceholder: string;
  value?: string;
  ctaText: string;
  onAction: (value: string) => void;
  errorText?: string;
  snackbarText?: string;
  isError?: boolean;
  snackbarError?: boolean;
  isSnackbarActive: boolean;
  setIsSnackbarActive;
};

const CallToAction: FunctionComponent<CallToActionProps> = ({
  variant = 'full-screen',
  header,
  body,
  inputPlaceholder,
  value = '',
  ctaText,
  onAction,
  errorText,
  isError,
  snackbarText,
  snackbarError,
  isSnackbarActive,
  setIsSnackbarActive,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className={`call-to-action-container ${variant}`}>
      <div className="cta-header">{header}</div>
      <div className="cta-body">{body}</div>
      <InputField
        extended={false}
        placeholder={inputPlaceholder}
        value={inputValue || ''}
        onChange={onInputChange}
        errorText={errorText}
        isError={isError}
        className="cta-input"
      />
      <Snackbar
        isActive={isSnackbarActive}
        setIsActive={setIsSnackbarActive}
        text={snackbarText}
        isError={snackbarError}
      />
      <Button
        type="tertiary"
        handleClick={() => {
          onAction(inputValue);
          setInputValue('');
        }}
        className="cta-action"
      >
        {ctaText}
      </Button>
    </div>
  );
};

export default CallToAction;
